import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
import { getFunctions, httpsCallable } from 'firebase/functions'; 
 
 import { 
    emitEvent 
} from './utility.js'
import { functions } from './intrivity_app.js';
import { INTRIVITY_MASQUERADE } from './nav.js';

    
// Manage Clients
export class IntrivityAdminUsers extends LitElement {
  
    static get styles() {
        return css`

        `;
    }
    
    static get properties() {
        return {
            user_profile: { type: Object },
            expanded: { type: Boolean },
            masquerade_email: { type: String }
        };
    }

    constructor() {
        super();
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback(); 
    }
    
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    render() {
        return html`
            
            <div style="margin-bottom: 5px; width: 100%; color: black; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: gray;">
                USERS  
                ${this.expanded ? html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                `: html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                `}
            </div>            
            
            ${this.expanded ? html`
                    <mwc-textfield style="width: 70%;" @change=${(e)=>{ 
                     this.masquerade_email = e.target.value;
                    }} label="Email of user to Masquerade"></mwc-textfield>
                    <mwc-button @click=${(e)=> { emitEvent(INTRIVITY_MASQUERADE,{ email: this.masquerade_email },this); }}>Masquerade</mwc-button>
                    
                    <button @click=${
                        (e)=> {  
                            const startPayment = httpsCallable(functions, 'startPayment');
                            startPayment({ text: 'messageText' })
                            .then((result) => {
                              const data = result.data;
                              console.log(data);
                            });
                        }
                    }></button>
            `: html`

            `}
            
        `
    } 
 
}
if (!customElements.get('intrivity-admin-users')) customElements.define('intrivity-admin-users', IntrivityAdminUsers);
