// The improved version of the My Intrivity Application
import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, getAdditionalUserInfo, signInWithPopup, signOut, connectAuthEmulator, 
           EmailAuthProvider, GoogleAuthProvider } from "firebase/auth"; 
import { doc, setDoc, getDoc, getDocs, collection, query, where, addDoc, getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions';

import '@material/mwc-button';
import '@material/mwc-formfield';
import '@material/mwc-checkbox';
import '@material/mwc-textfield';
import '@material/mwc-textarea';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';

import { 
    emitEvent, loadJSONFromStorage, getJSONFromStorage, getScore,
    INTRIVITY_LOAD_RESOURCES, INTRIVITY_LOAD_CLIENTS, INTRIVITY_LOAD_GROUPS, 
    INTRIVITY_GROUP_REPORTS_LOADED, INTRIVITY_GOOGLE_LOGGED_IN, 
    INTRIVITY_GOOGLE_LOGGED_OUT, INTRIVITY_LOGIN, INTRIVITY_LOGGED_IN, 
    INTRIVITY_LOGOUT, INTRIVITY_NAV_SELECT, INTRIVITY_PRINTABLE_TOGGLE, 
    INTRIVITY_GROUP_SELECT, INTRIVITY_MASQUERADE 
} from './utility.js'
import './badge.js';
import './header_app.js';
import './nav_app.js';
import './name_banner_app.js';
import './profile_app.js';
import './user_app.js';
import './teams.js';
import './overview.js';
import './printable_app.js';
import './admin_config.js';
import './admin_clients.js';
import './admin_users.js';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBPf-WUuhm7v-ixrQpuCuqg6xOG18ixLkk",
  authDomain: "my.intrivity.com",
  projectId: "intrivity-12c92",
  storageBucket: "intrivity-12c92.appspot.com",
  messagingSenderId: "931317535189",
  appId: "1:931317535189:web:8dfa9bb85f34f2828a6d5c",                   
  measurementId: "G-BEK1VQRZX6"                                         // optional
};
const app = initializeApp(firebaseConfig);                              
const provider = new GoogleAuthProvider();

// STATIC VARIABLES
export let user;                                                        // Universal user value
export let defaultGroup;                                                // Universal default group for user
export const storage = getStorage();                                    // Firebase storage
export const functions = getFunctions(app);                             // Firebase functions
export const auth = getAuth(app);                                       // Firebase auth
export const db = getFirestore(app);                                    // Firestore database

// Firebase authentication state change handler
onAuthStateChanged(auth, (guser) => {
    if (guser) {          
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        console.log("AuthStageChange: Google Signed In Emit Event");
        //console.log(guser);
        user = guser;
        console.log(user);
        emitEvent(INTRIVITY_GOOGLE_LOGGED_IN,{},document);
    } else {
        // User is signed out
        user = undefined;
        emitEvent(INTRIVITY_GOOGLE_LOGGED_OUT,{},document);
        console.log("AuthStageChange: Google Signed Out Emit Event");
    }
});


export class IntrivityApp extends LitElement {

    static get properties() {
        return {
            user_profile: { type: Object },         // current user data
            masqueraded_user: { type: String },     // the user currently masqueraded
            selection: { type: String },            // page selected
            report: { type: Object },               // user mrts
            group_report: { type: Object },         // group mrts
            group_reports: { type: Array },         // user groups
            printable: { type: Boolean },           // printable view
            group_code: { type: String },           // the group code for user
            space_domain: { type: Object },         // report data
            message_domain: { type: Object },       // report data
            relationship_domain: { type: Object },  // report data
            time_domain: { type: Object },          // report data
            
            current_selection: { type: String },

            base: {type: String},

            sample: { type: String },


            post: { type: String },
            callback: { type: String },
            payment: { type: String },
            clients: { type: Array }
        };
    }

    constructor() {
        super();
        this.selection = "overview";
        this.base = "";
        this.post = "no";
        this.callback = "no";
        this.role = "Student";
        this.printable = false;
        
        // set context of methods in case of multiple instances
        this.getUserProfile = this.getUserProfile.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.loggedout = this.loggedout.bind(this);
        this.loadGroupFromStorage = this.loadGroupFromStorage.bind(this);
        this.loadGroups = this.loadGroups.bind(this);
        this.getUserGroup = this.getUserGroup.bind(this);
        this.handleSelection = this.handleSelection.bind(this);  
        this.groupSelected = this.groupSelected.bind(this);
        this.loadClients = this.loadClients.bind(this);
        this.masquerade = this.masquerade.bind(this);
        this.quit_masquerade = this.quit_masquerade.bind(this);
        this.togglePrintable = this.togglePrintable.bind(this);
        this.callbackRedirect = this.callbackRedirect.bind(this);
        
    }
       
    firstUpdated() {

        if (this.callback === 'yes') {
            this.callbackRedirect();
        } else {
            getJSONFromStorage(this, "message_domain", "domains/domain_message.json", this.masqueraded_user);
            getJSONFromStorage(this, "space_domain", "domains/domain_space.json", this.masqueraded_user);
            getJSONFromStorage(this, "time_domain", "domains/domain_time.json", this.masqueraded_user);
            getJSONFromStorage(this, "relationship_domain", "domains/domain_relationship.json", this.masqueraded_user);
            //this.loadGroups(); // this fails because of race condition.. user profile may not be loaded yet!
        }        

    }
    
    connectedCallback() {
        super.connectedCallback();
        document.addEventListener(INTRIVITY_GOOGLE_LOGGED_IN, this.getUserProfile); // user logs in with google
        document.addEventListener(INTRIVITY_LOGIN, this.login);                     // log user in
        document.addEventListener(INTRIVITY_LOGGED_IN, this.getUserProfile);        // user logd in?
        document.addEventListener(INTRIVITY_GOOGLE_LOGGED_OUT, this.loggedout);     // user logs out with google 
        document.addEventListener(INTRIVITY_LOGOUT, this.logout);
        document.addEventListener(INTRIVITY_NAV_SELECT, this.handleSelection);      // page selection
        document.addEventListener(INTRIVITY_GROUP_SELECT, this.groupSelected);      // comparison group selected
        document.addEventListener(INTRIVITY_LOAD_CLIENTS, this.loadClients);        // load client data
        document.addEventListener(INTRIVITY_MASQUERADE, this.masquerade);           // masquerade
        document.addEventListener(INTRIVITY_PRINTABLE_TOGGLE, this.togglePrintable);
        window.addEventListener("resize", (e)=> { this.requestUpdate(); });        
    }

    disconnectedCallback() {
        document.removeEventListener(INTRIVITY_GOOGLE_LOGGED_IN, this.getUserProfile);
        document.removeEventListener(INTRIVITY_GOOGLE_LOGGED_OUT, this.loggedout);
        document.removeEventListener(INTRIVITY_LOGIN, this.login);
        document.removeEventListener(INTRIVITY_LOGGED_IN, this.getUserProfile);
        document.removeEventListener(INTRIVITY_LOGOUT, this.logout);
        document.removeEventListener(INTRIVITY_NAV_SELECT, this.handleSelection);
        document.removeEventListener(INTRIVITY_GROUP_SELECT, this.groupSelected);
        document.removeEventListener(INTRIVITY_LOAD_CLIENTS, this.loadClients);
        document.removeEventListener(INTRIVITY_MASQUERADE, this.masquerade);
        document.removeEventListener(INTRIVITY_PRINTABLE_TOGGLE, this.togglePrintable);
        window.removeEventListener("resize", (e)=> { this.requestUpdate(); });    
        super.disconnectedCallback();
    }

    async login() {
        signInWithPopup(auth, provider)
            .then((result) => {
              const credential = GoogleAuthProvider.credentialFromResult(result);
              user = result.user;
              user.credential = credential;
              user.token = credential.accessToken;
              user.additional = getAdditionalUserInfo(result);
              this.getUserProfile();
            }).catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              const email = error.customData.email;
              //const credential = GoogleAuthProvider.credentialFromError(error);
              console.log("User failed to login.");
        });        
    }    

    logout() {
        console.log("logout called");
        signOut(auth).then(() => {
            this.loggedout();
        }).catch((error) => {
            if (this.callback === 'yes') {
                location.href = "https://my.intrivity.com";
            }
        }); 
    }
    
    loggedout() {
        user = undefined;
        this.user_profile = undefined;
        this.report = undefined;
        this.group_report = undefined;
        this.group_reports = undefined;
        
        localStorage.removeItem("report");
        localStorage.removeItem("group_report");
        this.selection = "overview";
        this.requestUpdate(); 
        
        if (this.callback === 'yes') {
            location.href = "https://my.intrivity.com";
        }
    }
         
    async getUserProfile() {
        if (user) {
            console.log("Getting the user's profile: " + (this.masqueraded_user ? this.masqueraded_user: user.email));
            const docRef = doc(db, "users", this.masqueraded_user ? this.masqueraded_user: user.email);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                this.user_profile = docSnap.data();
                console.log("Found user's profile: ");
                console.log(this.user_profile);
                //if (this.user_profile.group)
                //    this.getUserGroup(this.user_profile.group);
            } else {
                
                // No user profile
                if (this.masqueraded_user) {
                    alert("Masqueraded user not found!");
                    this.masqueraded_user = undefined;
                    return;
                }
                
                this.user_profile = {};
               
                this.user_profile.email = user.email;
                this.user_profile.displayName = user.displayName;
                if (user.firstName) {
                    this.user_profile.firstName = user.firstName; 
                } else {
                    if (user.additional && user.additional.profile && user.additional.profile.given_name) 
                        this.user_profile.firstName = user.additional.profile.given_name;
                }
                if (user.lastName) {
                    this.user_profile.lastName = user.lastName; 
                } else {
                    if (user.additional && user.additional.profile && user.additional.profile.family_name) 
                        this.user_profile.lastName = user.additional.profile.family_name;
                }
                this.user_profile.fullName = this.user_profile.displayName;
                
                if (user.photoURL) this.user_profile.image = user.photoURL;
                
                try {
                    await setDoc(doc(db, "users", user.email), this.user_profile, { merge: true });
                } catch (err) {
                    alert("There was a problem saving your profile.");
                    console.log("Error saving user data to firestore.");
                    console.log(err);
                }
                console.log("Created new user from " + user);
            }
            
            // Just got the user profile- push user to view user page
            this.selection = "user";    
            console.log("Getting report.. " + "reports/" +  (this.masqueraded_user ? this.masqueraded_user: user.email));
            getJSONFromStorage(this, "report", "reports/" +  (this.masqueraded_user ? this.masqueraded_user: user.email) + ".json", this.masqueraded_user);  
 
            this.loadGroups();
            if (this.user_profile.group)
                    this.getUserGroup(this.user_profile.group);
            
            
        }  
                
        let group_hash = window.location.hash;
        if (group_hash && group_hash.length > 0) { 
            this.group_code = group_hash.substring(1);
            console.log(this.group_code);
        }
        
        this.requestUpdate();
    }
    
    async loadGroups() {
        console.log("Loading user groups...");
        let groups = [];
        if (this.user_profile && this.user_profile.groups) {
            
            for (let i=0; i < this.user_profile.groups.length; i++) {
                try {
                    let val = localStorage.getItem("reports_" + this.user_profile.groups[i]);
                    if (val) {
                        
                        let group = JSON.parse(val);
                        
                        // check if last update version is > 1 day old
                        if (group.stored) {
                            let now = new Date();
                            let stored = new Date(group.stored);
                            let dayAgo = new Date(now.setDate(now.getDate() - 1));
                            if (dayAgo > stored) 
                                await groups.push(this.loadGroupFromStorage(groups, this.user_profile.groups[i], group));
                            else 
                                groups.push(group);
                        } else {
                            await groups.push(this.loadGroupFromStorage(groups, this.user_profile.groups[i] ));
                        }

                    } else {
                        await groups.push(this.loadGroupFromStorage(groups, this.user_profile.groups[i] ));
                    }
                } catch (e) {
                    console.log("Error loading group " + this.user_profile.groups[i]);
                    console.log(e);
                }                
                
            }
        } else {
            console.log("did not load groups");
        }
        this.group_reports = groups;
        console.log(this.group_reports.length + " groups loaded.");
        this.requestUpdate();
        //emitEvent(INTRIVITY_GROUP_REPORTS_LOADED,{},document);
        
    }
    
    async loadGroupFromStorage(groups, group, failover) {
        try {
            const storageRef = ref(storage, "reports/groups/" + group + ".json");
            getDownloadURL(storageRef)
                .then(url => fetch(url))
                .then(response => response.json())
                .then(data => { 
                    groups.push(data); 
                    let store_this = cloneDeep(data);
                    store_this.stored = new Date().toISOString();
                    localStorage.setItem("reports_" + group, JSON.stringify(store_this));
                });
        } catch (x) {
            console.log(x);
            if (failover) groups.push(failover);
            return group;
        }
    }
    
    async getUserGroup(gr) {
        //const docRef = doc(db, "groups", gr);
        //const docSnap = await getDoc(docRef);

        //if (docSnap.exists()) {
        //    defaultGroup = docSnap.data();
            getJSONFromStorage(this, "group_report", "reports/groups/" + gr + ".json", this.masqueraded_user);  
        //} 
        this.requestUpdate();       
    }
    
    handleSelection(e) {
        if (e.detail.selection !== this.selection) {
            this.selection = e.detail.selection;
            window.scrollTo(0, 0);
            this.requestUpdate();
        }
    }
    
    groupSelected(e) {
        this.group_report = e.detail;
        this.requestUpdate();
    }
    
    async loadClients() {
        console.log("loading clients");
        const snapshot = await getDocs(collection(db, "clients"));
        this.clients = snapshot.docs.map(doc => doc.data());
        console.log(this.clients);
        this.requestUpdate();
    }    
    
    masquerade(e) {
        this.masqueraded_user = e.detail.email;
        localStorage.removeItem('report');    
        localStorage.removeItem('group_report');
        this.getUserProfile(); 
    }
    
    quit_masquerade(e) {
        this.masqueraded_user = undefined;
        localStorage.removeItem('report');    
        localStorage.removeItem('group_report');
        this.getUserProfile();
    }
    
    togglePrintable(e) {
        this.printable = !this.printable;
    }
    
    callbackRedirect() {
        console.log("SETTING THE CALLBACK");
        setTimeout(this.logout, 8000);
        //setTimeout(function() {
        //    console.log("Callback called...");
        //    this.logout();
        //    //location.href = "https://my.intrivity.com";
        //}, 8000);
    }
    
    static get styles() {
      return css` 
        @media print { 
  
            /* Define the header and footer */ 
            @page { 
                margin: 0 !important; 
            } 
  
        } 
      `;
    }
    
    render() {
        return html`
            ${this.callback === 'yes' ? html`   
                <div style="display: grid; grid-template-columns: 1fr;">
                    <intrivity-header .printable=${this.printable} .user_profile=${this.user_profile} style="grid-column-start: 1; grid-column-end: ${window.innerWidth < 600 ? '2': '3'};"></intrivity-header>
                    ${this.payment === 'success' ? html`
                            <h3 style="font-size: larger; height: 300px; display: grid; align-items: center; margin: auto;">Payment was successful... this site will redirect back to your Intrivity results.</h3>              
                    `: html`
                        ${this.payment === 'fail' ? html`
                                <h3 style="font-size: larger; height: 300px; display: grid; align-items: center; margin: auto;">Payment failed.</h3>              
                        `: html`
                            ${this.payment === 'cancel' ? html`
                                    <h3 style="font-size: larger; height: 300px; display: grid; align-items: center; margin: auto;">Payment was cancelled... this site will redirect back to your Intrivity results.</h3>              
                            `: html`
                                    <h3 style="font-size: larger; height: 300px; display: grid; align-items: center; margin: auto;">Please wait... processing results...</h3>  
                            `}
                        `}
                    `}
                </div>        
            `: html`
                ${this.space_domain && this.message_domain && this.relationship_domain && this.time_domain ? html`
                    ${this.printable ? html`
                        <intrivity-printable .group_reports=${this.group_reports} .group_report=${this.group_report} .user_profile=${this.user_profile} .report=${this.report} .message_domain=${this.message_domain} .space_domain=${this.space_domain} .relationship_domain=${this.relationship_domain} .time_domain=${this.time_domain}></intrivity-printable>
                    `: html`

                        <div style="display: grid; grid-template-columns: ${window.innerWidth < 600 ? '1fr': '1fr 4fr'};">

                            <intrivity-header .printable=${this.printable} .user_profile=${this.user_profile} style="grid-column-start: 1; grid-column-end: ${window.innerWidth < 600 ? '2': '3'};"></intrivity-header>

                            <intrivity-nav .report=${this.report} .group_reports=${this.group_reports} .user_profile=${this.user_profile} .selection=${this.selection} .horizontal=${window.innerWidth < 600}></intrivity-nav>

                            <div>

                                ${this.report && this.group_reports ? html`
                                    <intrivity-name-banner .group_report=${this.group_report} .group_reports=${this.group_reports} .user_profile=${this.user_profile} size="0.9" m="${getScore(this.report.m.average)}" r="${getScore(this.report.r.average)}" t="${getScore(this.report.t.average)}" s="${getScore(this.report.s.average)}"></intrivity-name-banner>
                                `: html`
                                    <intrivity-name-banner .user_profile=${this.user_profile} size="0.9"></intrivity-name-banner>
                                `}

                                ${this.selection === 'user' ? html`<intrivity-user .group_reports=${this.group_reports} .report=${this.report} .user_profile=${this.user_profile}></intrivity-user>`: html``}
                                ${this.selection === 'teams' ? html`<intrivity-teams .group_reports=${this.group_reports} .group_report=${this.group_report}></intrivity-teams>`: html``}
                                ${this.selection === 'profile' ? html`
                                    <intrivity-profile .group_report=${this.group_report} .report=${this.report} .message_domain=${this.message_domain} .space_domain=${this.space_domain} .relationship_domain=${this.relationship_domain} .time_domain=${this.time_domain}></intrivity-profile>
                                `: html``}
                                ${this.selection === 'overview' ? html`<intrivity-overview></intrivity-overview>`: html``}
                                ${this.selection === 'message' ? html`
                                    <intrivity-domain-details domain_prefix="m" .domain=${this.message_domain} .group_reports=${this.group_reports} .group_report=${this.group_report} .report=${this.report}></intrivity-domain-details>
                                `: html``}
                                ${this.selection === 'space' ? html`
                                    <intrivity-domain-details domain_prefix="s" .domain=${this.space_domain} .group_reports=${this.group_reports} .group_report=${this.group_report} .report=${this.report}></intrivity-domain-details>
                                `: html``}                    
                                ${this.selection === 'time' ? html`
                                    <intrivity-domain-details domain_prefix="t" .domain=${this.time_domain} .group_reports=${this.group_reports} .group_report=${this.group_report} .report=${this.report}></intrivity-domain-details>
                                `: html``}  
                                ${this.selection === 'relationship' ? html`
                                    <intrivity-domain-details domain_prefix="r" .domain=${this.relationship_domain} .group_reports=${this.group_reports} .group_report=${this.group_report} .report=${this.report}></intrivity-domain-details>
                                `: html``}  
                                ${this.user_profile && this.selection === 'admin' ? html`
                                    <intrivity-admin-config .user_profile=${this.user_profile}></intrivity-admin-config>
                                    <intrivity-admin-clients .clients=${this.clients} .user_profile=${this.user_profile}></intrivity-admin-clients>
                                    <intrivity-admin-users .user_profile=${this.user_profile}></intrivity-admin-users>
                                `: html``}
                                ${this.masqueraded_user ? html`
                                    <mwc-button @click=${this.quit_masquerade}>Stop Masquerade</mwc-button>
                                `: html``}

                            </div>

                        </div>


                    `}
                `: html`<h3>Loading...</h3>`}
            `}
        `
    }
     
}
if (!customElements.get('intrivity-app')) customElements.define('intrivity-app', IntrivityApp);


