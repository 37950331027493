// The improved version of the My Intrivity Application
import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './badge.js';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';

import { user } from './intrivity_app.js';
import { 
    emitEvent, INTRIVITY_LOGIN, INTRIVITY_NAV_SELECT, INTRIVITY_GROUP_SELECT
} from './utility.js'

export class IntrivityNameBannerApp extends LitElement {

    static get styles() {
        return css`
            .banner {
                width: 100%;
                border-bottom: 7px solid rgb(250, 166, 53);
                display: grid;
                grid-template-columns: 3fr 1fr ;
            }
            .banner_badge {
                display: grid;
                grid-template-columns: 50px 1fr ;
                margin: auto;
                cursor: pointer;
            }  
            @media print { 
                intrivity-group-selector {
                    display: none;
                }
            }
            
        `;
    }
    
    static get properties() {
        return {
            user_profile: { type: Object },
            group_report: { type: Object },
            group_reports: { type: Array },
            m: { type: Number },
            r: { type: Number },
            t: { type: Number },
            s: { type: Number },
            size: { type: Number },
            printable: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.size = 1;
    }

    render() {
        return html`
            <div class="banner">
                <div>
                    <h1 style="font-size: 2.1vmax; margin-left: 20px;">
                        ${user ? html`
                            ${this.printable ? html`
                                Assessment for ${this.user_profile.displayName}
                            `: html`
                                Welcome Back, ${this.user_profile.firstName ? this.user_profile.firstName: this.user_profile.displayName}!
                            `}
                        `: html`
                            Welcome!  Please log in to My Intrivity!
                        `}
                        
                    </h1>
                    <intrivity-group-selector .group_report=${this.group_report} .group_reports=${this.group_reports}></intrivity-group-selector>
                </div>
                    
                ${ this.user_profile.paid && this.m && this.r && this.t && this.s ? html`
                        <div class="banner_badge" @click=${(e) => { 
                                    emitEvent(INTRIVITY_NAV_SELECT, { "selection": "profile" }, this);
                                } }>
                            <intrivity-badge-plain .m=${this.m} .r=${this.r} .s=${this.s} .t=${this.t} .size=${this.size}></intrivity-badge-plain>
                            <div style="margin-left: 5px; font-size: larger;">
                                M R T S<br />
                                ${this.m} ${this.r} ${this.t} ${this.s}
                            <div>
                        </div>
                `: html``}
                
            </div>
        `
    } 
 
}
if (!customElements.get('intrivity-name-banner')) customElements.define('intrivity-name-banner', IntrivityNameBannerApp);

export class IntrivityGroupSelector extends LitElement {
       
    static get properties() {
        return {
            group_report: { type: Object },
            group_reports: { type: Array }
        };
    }

    constructor() {
        super();
    }
    
    render() {
        return html`
            ${this.group_reports ? html`
                <div style="margin-left: 20px;">
                    Compare with 
                    <select @change=${(e)=>{ emitEvent(INTRIVITY_GROUP_SELECT, JSON.parse(e.target.value), this); }}>
                        ${this.group_reports.map((report, i) => html`
                            ${report.title ? html`<option ?selected=${report.title === this.group_report.description} value=${JSON.stringify(report)}>${report.title}</option>`: html``}  
                        `)}
                    </select>
                </div>
            `: html``}
        `
    } 
}
if (!customElements.get('intrivity-group-selector')) customElements.define('intrivity-group-selector', IntrivityGroupSelector);
