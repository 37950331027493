// The improved version of the My Intrivity Application
import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import { 
    emitEvent, loadJSONFromStorage, getJSONFromStorage, getScore, hasRole,
    INTRIVITY_LOAD_RESOURCES, INTRIVITY_LOAD_CLIENTS, INTRIVITY_LOAD_GROUPS, 
    INTRIVITY_GROUP_REPORTS_LOADED, INTRIVITY_GOOGLE_LOGGED_IN, 
    INTRIVITY_GOOGLE_LOGGED_OUT, INTRIVITY_LOGIN, INTRIVITY_LOGGED_IN, 
    INTRIVITY_LOGOUT, INTRIVITY_NAV_SELECT, INTRIVITY_PRINTABLE_TOGGLE, 
    INTRIVITY_GROUP_SELECT, INTRIVITY_MASQUERADE 
} from './utility.js'
import './badge.js';

export class IntrivityNavApp extends LitElement {

    static get styles() {
        return css`
            .nav {
                background-color: rgb(87, 130, 133);
                color: white;
                height: 100%;
                font-size: 24px;
                min-height: 600px;
            }
            .selection {
                cursor: pointer;
                font-size: 2.0vmax;
                padding-top: 5px; 
                padding-bottom: 5px; 
                padding-left: 40px;
            }
            .selected {
                font-size: 2.0vmax;
                color: rgb(87, 130, 133); 
                background-color: rgb(175, 208, 117);
            }
            .common {
                background-color: rgb(250, 166, 53); // ORANGE
                background-color: rgb(82, 162, 170); // TEAL
                background-color: rgb(175, 208, 117); // GREEN
                background-color: rgb(217, 90, 123); // PINK
                background-color: rgb(143, 143, 143); // GRAY
            }
        `;
    }
    
    static get properties() {
        return {
            selection: { type: String },
            user_profile: { type: Object },     // user data
            report: { type: Object },
            horizontal: { type: Boolean },
            group_reports: { type: Array }
        };
    }

    handleSelection(e) {
        if (e.detail.selection !== this.selection) {
            console.log(e);
            this.selection = e.detail.selection;
            this.requestUpdate();
        }
    }
    
    constructor() {
        super(); 
        this.selection = "overview";
        this.handelSelection = this.handleSelection.bind(this);
        //this.horizontal = false;
        //this.selection = "overview";
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener(INTRIVITY_NAV_SELECT, this.handleSelection); 
    }
    
    disconnectedCallback() {
        this.removeEventListener(INTRIVITY_NAV_SELECT, this.handleSelection);
        super.disconnectedCallback();
    }
    
    render() {
        return html`
            <div class="nav">
        
                ${this.horizontal ? html` 
                    Mobile Menu 
                `: html`
        
                    <div style="padding-top: 100px;">
                        <div class="selection ${this.selection === 'overview' ? 'selected': ''}" @click=${(e) => { 
                            emitEvent(INTRIVITY_NAV_SELECT, { "selection": "overview" }, this);
                        } }>Overview</div>
                    </div>
        
                    ${this.user_profile ? html`

                       
                        <div style="padding-top: 30px;">
                            ${ this.report && this.user_profile.paid ? html`
                                <div class="selection ${this.selection === 'profile' ? 'selected': ''}" @click=${(e) => { 
                                    emitEvent(INTRIVITY_NAV_SELECT, { "selection": "profile" }, this);
                                } }>My MRTS<br /> Profile</div>
                            `: html``}
                        </div>

                        <div style="padding-top: 80px;">
                            ${ this.report && this.user_profile.paid ? html`
                                <div class="selection ${this.selection === 'message' ? 'selected': ''}" @click=${(e) => { 
                                    emitEvent(INTRIVITY_NAV_SELECT, { "selection": "message" }, this);
                                } }>Message<br /> Domain Details</div>
                            `: html``}
                        </div>
                        <div style="padding-top: 30px;">
                            ${ this.report && this.user_profile.paid ? html`
                                <div class="selection  ${this.selection === 'relationship' ? 'selected': ''}" @click=${(e) => { 
                                    emitEvent(INTRIVITY_NAV_SELECT, { "selection": "relationship" }, this);
                                } }>Relationship<br /> Domain Details</div>
                            `: html``}
                        </div>
                        <div style="padding-top: 30px;">
                            ${ this.report && this.user_profile.paid ? html`
                                <div class="selection  ${this.selection === 'time' ? 'selected': ''}" @click=${(e) => { 
                                    emitEvent(INTRIVITY_NAV_SELECT, { "selection": "time" }, this);
                                } }>Time Domain<br /> Details</div>
                            `: html``}
                        </div>
                        <div style="padding-top: 30px;">
                            ${ this.report && this.user_profile.paid ? html`
                                <div class="selection  ${this.selection === 'space' ? 'selected': ''}" @click=${(e) => { 
                                    emitEvent(INTRIVITY_NAV_SELECT, { "selection": "space" }, this);
                                } }>Space Domain<br /> Details</div>
                            `: html``}
                        </div>
                     

                        <div style="padding-top: 80px;">
                            <div class="selection  ${this.selection === 'user' ? 'selected': ''}" @click=${(e) => { 
                                emitEvent(INTRIVITY_NAV_SELECT, { "selection": "user" }, this);
                                //emitEvent(INTRIVITY_LOAD_GROUPS,{},this);
                            } }>My Intrivity</div>
                        </div>
        
                        
                        <div style="padding-top: 30px;">
                            ${this.user_profile && this.user_profile.paid && this.user_profile.groups && this.group_reports && this.group_reports.length > 0 ? html`
                                <div class="selection  ${this.selection === 'teams' ? 'selected': ''}" @click=${(e) => { 
                                    emitEvent(INTRIVITY_NAV_SELECT, { "selection": "teams" }, this);
                                    //emitEvent(INTRIVITY_LOAD_GROUPS,{},this);
                                } }>My Teams</div>
                            `: html``}
                        </div>
                        
                        ${hasRole('admin', this.user_profile) ? html`
                       ${ this.report && this.user_profile.paid ? html`
                            <div style="padding-top: 80px; padding-bottom: 80px;">
                                <div class="selection" @click=${(e) => { 
                                    emitEvent(INTRIVITY_PRINTABLE_TOGGLE, {}, this);
                                } }>Printable Report</div>
                            </div>
                        `: html``}
                        `: html``}
        
                        ${hasRole('admin', this.user_profile) ? html`
                        <div style="padding-top: 80px; padding-bottom: 80px;">
                            <div class="selection ${this.selection === 'admin' ? 'selected': ''}" @click=${(e) => { 
                                emitEvent(INTRIVITY_NAV_SELECT, { "selection": "admin" }, this);
                                //emitEvent(INTRIVITY_LOAD_GROUPS,{},this);
                                emitEvent(INTRIVITY_LOAD_CLIENTS,{},this);
                            } }>Administration</div>
                        </div>
                        `: html``}
                     
                        
        
                    `: html``}
                `}
        
            </div>
        `
    } 
 
}
if (!customElements.get('intrivity-nav')) customElements.define('intrivity-nav', IntrivityNavApp);
